import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { AUTH_TOKEN, REGISTER_TOKEN, CAPTCHA_TOKEN } from "redux/constants/Auth"
import { onMobileNavToggle } from "redux/actions/Theme";
import { APP_PREFIX_PATH } from 'configs/AppConfig'
/*import { appContext } from "services/Context"*/
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import utils from 'utils'
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, NAV_MODE } from "constants/ThemeConstant";
import { Menu, Grid } from "antd";
import {
  DashboardOutlined,
  AppstoreOutlined,
  MailOutlined,
  MessageOutlined,
  CalendarOutlined,
  BarChartOutlined,
  SolutionOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  CreditCardOutlined,
  WalletOutlined,
  FileDoneOutlined,
  ApartmentOutlined,
  CustomerServiceOutlined,
  LineChartOutlined,
    CloudServerOutlined
} from '@ant-design/icons';
import moment from 'moment'
import { Manager } from "socket.io-client";
import {useSelector} from "react-redux";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;


const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
	const { sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle, navigationConfig } = props;
	const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
	const closeMobileNav = () => {
		if (isMobile) {
			onMobileNavToggle(false)
		}
	}

    const user = useSelector((state)=> state.user.user);
  const panelAccess = user.panelAccess;

  var startLogin = moment(user?.last_online).toDate();
  var timeNow = new Date();
  if(timeNow.getHours() - startLogin.getHours() >= 12) {
      /*localStorage.removeItem(AUTH_TOKEN);*/
      localStorage.removeItem(REGISTER_TOKEN);
      localStorage.removeItem(CAPTCHA_TOKEN);
      window.location.reload();
  }
  
  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode={NAV_MODE}
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {navigationConfig.map((menu) => {
        if (String(menu.allowed).includes(String(user?.panelAccess)) || menu.allowed === null) {
          if ( menu.submenu.length > 0 ) {
            return (
              <Menu.ItemGroup key={menu.key} title={setLocale(localization, menu.title)}>
                {menu.submenu.filter((elm) => panelAccess.includes(String(elm.allowed)) || elm.allowed === null).map((subMenuFirst) =>
                  subMenuFirst.submenu.length > 0 ? (
                    <SubMenu
                      icon={
                        subMenuFirst.icon ? (
                          <Icon type={subMenuFirst?.icon} />
                        ) : null
                      }
                      key={subMenuFirst.key}
                      title={setLocale(localization, subMenuFirst.title)}
                    >
                      {subMenuFirst.submenu.map((subMenuSecond) => (
                        <Menu.Item key={subMenuSecond.key}>
                          {subMenuSecond.icon ? (
                            <Icon type={subMenuSecond?.icon} />
                          ) : null}
                          <span>
                            {setLocale(localization, subMenuSecond.title)}
                          </span>
                          <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  ) : (
                    <Menu.Item key={subMenuFirst.key}>
                      {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                      <span>{setLocale(localization, subMenuFirst.title)}</span>
                      <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                    </Menu.Item>
                  )
                )}
              </Menu.ItemGroup>
            )
          } else {
            return (
              <Menu.Item key={menu.key}>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu?.title)}</span>
                {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
              </Menu.Item>
            )
          }
        }
      })}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization, navigationConfig } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const dashBoardNavTree =[
  {
    key: 'dashboard',
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: 'sidenav.dashboard',
    allowed: null,
    submenu: [
        {
            key: 'dashboard',
            path: `${APP_PREFIX_PATH}/dashboard/default`,
            title: 'sidenav.dashboard.default',
            icon: BarChartOutlined,
            breadcrumb: false,
            submenu: [],
            allowed: null
        },
        {
          key: 'analysis',
          path: `${APP_PREFIX_PATH}/dashboard/analysis`,
          title: 'sidenav.dashboard.analysis',
          icon: LineChartOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: 'analysis'
      }
    ]
  }
]

const usersNavTree = [
  {
    key: "users",
    path: `${APP_PREFIX_PATH}/users`,
    title: "sidenav.users",
    allowed: null,
    submenu: [
      {
          key: 'users-list',
          path: `${APP_PREFIX_PATH}/users/user-list`,
          title: 'sidenav.users.userlist',
          icon: SolutionOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: 'user-list'
      },
      {
          key: "users-documents",
          path: `${APP_PREFIX_PATH}/users/documents`,
          title: "sidenav.users.documents",
          icon: FileDoneOutlined,
          breadcrumb: true,
          submenu: [],
          allowed: 'documents'
      },
      {
          key: "users-funding-methods",
          path: `${APP_PREFIX_PATH}/users/funding-methods`,
          title: "sidenav.users.fundingmethods",
          icon: CreditCardOutlined,
          breadcrumb: true,
          submenu: [],
          allowed: 'funding-methods'
      },
      {
          key: 'users-trading-accounts',
          path: `${APP_PREFIX_PATH}/users/trading-accounts`,
          title: 'sidenav.users.accounts',
          icon: AppstoreOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: 'trading-accounts'
      },
        {
            key: "apps-vps",
            path: `${APP_PREFIX_PATH}/vps`,
            title: "sidenav.accounts.vps",
            icon: CloudServerOutlined,
            breadcrumb: false,
            submenu: [],
            allowed: 'vps',
        },

    ],
  }
]

const financialNavTree =[
  {
    key: 'financial',
    path: `${APP_PREFIX_PATH}/financial`,
    title: 'sidenav.dashboard.financial', 
    icon: DashboardOutlined,
    breadcrumb: false,
    allowed: null,
    submenu: [
      {
          key: 'financial-transactions-history',
          path: `${APP_PREFIX_PATH}/financial/transactions-history`,
          title: 'sidenav.financial.transactions',
          icon: CreditCardOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null
      },
      {
          key: 'financial-wallets',
          path: `${APP_PREFIX_PATH}/financial/wallets`,
          title: 'sidenav.financial.wallets',
          icon: WalletOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: 'wallets'
      }
    ]
  },          
]

const partnershipNavTree =[
  {
    key: 'partnership',
    path: `${APP_PREFIX_PATH}/partnership`,
    title: 'sidenav.partnership',
    allowed: null,
    submenu: [
      {
        key: 'partnership-ibs',
        path: `${APP_PREFIX_PATH}/partnership`,
        title: 'sidenav.partnership.ibs',
        icon: ApartmentOutlined,
        breadcrumb: false,
        submenu: [],
        allowed: null
      },
      {
        key: 'partnership-financial',
        path: `${APP_PREFIX_PATH}/partnership/general`,
        title: 'sidenav.partnership.financial',
        icon: CreditCardOutlined,
        breadcrumb: false,
        submenu: [],
        allowed: null
      }  
    ]
  }
]

const supportNavTree =[
  {    
    key: "support",
    path: `${APP_PREFIX_PATH}/support`,
    title: "sidenav.support",
    allowed: null,
    submenu: [
      {
            key: "support-mail",
            path: `${APP_PREFIX_PATH}/support/mail/inbox`,
            title: "sidenav.support.mail",
            icon: MailOutlined,
            breadcrumb: false,
            submenu: [],
            allowed: 'inbox'
      },
      {
            key: "support-chat",
            path: `${APP_PREFIX_PATH}/support/chat`,
            title: "sidenav.support.chat",
            icon: MessageOutlined,
            breadcrumb: false,
            submenu: [],
            allowed: 'chat'
      },
        {
            key: "support-tickets",
            path: `${APP_PREFIX_PATH}/support/tickets`,
            title: "sidenav.support.ticket",
            icon: CustomerServiceOutlined,
            breadcrumb: false,
            submenu: [],
            allowed: 'tickets'
        },
      {
          key: 'support-notes',
          path: `${APP_PREFIX_PATH}/support/notes`,
          title: 'sidenav.support.notes',
          icon: EditOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: 'notes'
      },
      {
            key: "support-calendar",
            path: `${APP_PREFIX_PATH}/support/calendar`,
            title: "sidenav.support.calendar",
            icon: CalendarOutlined,
            breadcrumb: true,
            submenu: [],
            allowed: 'calendar'
      },
      {
            key: "support-Faq",
            path: `${APP_PREFIX_PATH}/support/faq`,
            title: "sidenav.support.faq",
            icon: QuestionCircleOutlined,
            breadcrumb: false,
            submenu: [],
            allowed: 'faq'
      },
    ],
  },      
]

const settingNavTree =[
  {    
    key: "setting",
    path: `${APP_PREFIX_PATH}/setting`,
    title: "sidenav.setting",
    allowed: 'setting',
    submenu: [
      {
        key: "setting-faq",
        title: "sidenav.setting.faqSetting",
        icon: QuestionCircleOutlined,
        submenu: [
          {
            key: "setting-faqs-categories",
            path: `${APP_PREFIX_PATH}/setting/faqs-categories`,
            title: "sidenav.setting.faqsCategories",
            breadcrumb: false,
            submenu: [],
            allowed: 'faqs-categories'
          },
          {
            key: "setting-faqs",
            path: `${APP_PREFIX_PATH}/setting/faqs`,
            title: "sidenav.setting.faqs",
            breadcrumb: false,
            submenu: [],
            allowed: 'faqs'
          }
        ],
        allowed: 'faq-setting'
      },
    ]
  }     
]

const navigationConfig = [
  ...dashBoardNavTree,
  ...usersNavTree,
  ...financialNavTree,
  ...partnershipNavTree,
  ...supportNavTree,
  ...settingNavTree
]

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} navigationConfig={navigationConfig} />
  ) : (
    <TopNavContent {...props} navigationConfig={navigationConfig} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
