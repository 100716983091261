// API URL FOR DEVELOPMENT MODE
const dev = {
  API_ENDPOINT_URL: 'https://api-main.fortunamarkets.org'
};
// API URL FOR PRODUCTION MODE
const prod = {
  API_ENDPOINT_URL: 'https://api-main.fortunamarkets.org'
};
// API URL FOR TEST MODE
const test = {
  API_ENDPOINT_URL: 'https://api-test.fortunamarkets.org'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()