import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import user from "./User";

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    user: user
});

export default reducers;