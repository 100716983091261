import {
    SET_USER_DATA
} from '../constants/User';

const initState = {
    user: {}
}

const userData = (state = initState, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {
                ...state,
                user: action.payload,
            }
        default:
            return state;
    }
}

export default userData