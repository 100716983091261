import { serializeArray } from 'modules/index';
import { API_BASE_URL } from 'configs/AppConfig';

// ****************************** Static ****************************** //
// General
export const SETTING_GET_API = () => `${API_BASE_URL}/setting`;
export const ADVERTISING_MEDIA_GET_API = () => `${API_BASE_URL}/advertising-medias`;
export const CHART_GET_API = () => `${API_BASE_URL}/portal/chart?`;

// ****************************** Authentication**************************************** //
// 
export const POST_ADMIN_LOGIN_API = () => `${API_BASE_URL}/admin/sign-in`;
export const POST_FORGOT_PASSWORD_API = () => `${API_BASE_URL}/portal/forgot-password`;
export const POST_RESET_PASSSWORD_API = () => `${API_BASE_URL}/portal/reset-password`;

// ****************************** Dashboard ****************************** //
// 
export const GET_SUM_TRANSACTIONS_API = () => `${API_BASE_URL}/portal/wallet-history`;
export const GET_MANAGER_REPORT_API = () => `${API_BASE_URL}/portal/ib-users/total-commission`;
export const GET_SUM_SYMBOL_LOTS_API = () => `${API_BASE_URL}/portal/total-lots-symbols`;
export const GET_SUMMARY_USERS_ACCOUNT_API = () => `${API_BASE_URL}/portal/summary-of-accounts`;
export const GET_COUNT_USERS_API = () => `${API_BASE_URL}/portal/users/count`;
export const GET_COUNT_ACCOUNTS_API = () => `${API_BASE_URL}/accounts/count`;


// ****************************** Users ****************************** //
// User List
export const GET_USERS_API = () => `${API_BASE_URL}/admin/clients/`;
export const SEND_OTP_EMAIL_API = () => `${API_BASE_URL}/panel/users/send-otp-email`;
export const SEND_EMAIL_CONFIRMATOIN_API = () => `${API_BASE_URL}/portal/confirmation/email`;
export const UPDATE_EMAIL_CONFIRMATION_API = (clientNumber) => `${API_BASE_URL}/portal/users/confirmed-email/${clientNumber}`;
export const UPDATE_PHONE_CONFIRMATION_API = (clientNumber) => `${API_BASE_URL}/portal/users/confirmed-phone/${clientNumber}`;


// ****************************** Profile ****************************** //
// Edit Profile
export const USER_AVATAR_API = () => `${API_BASE_URL}/portal/user/avatar`;
export const USER_PROFILE_API = (clientNumber) => `${API_BASE_URL}/admin/clients/${clientNumber}`;
export const USER_API = (clientNumber) => `${API_BASE_URL}/admin/clients/${clientNumber}`;
export const USER_REMOVE_API = (users) => `${API_BASE_URL}/admin/clients?users=${users}`;

// Verification & Documents
export const USERS_DOCUMENTS_API = () => `${API_BASE_URL}/admin/documents`;

// Funding Methods
export const USERS_FUNDING_METHODS_API = () => `${API_BASE_URL}/admin/payment-methods`;

// Security
export const POST_CHANGE_USER_PASSWORD_API = () => `${API_BASE_URL}/portal/user/change-password`;
export const GET_SECURITY_PIN_API = () => `${API_BASE_URL}/portal/user/security-pin`;
export const GET_TWO_FACTOR_ENABLE_API = () => `${API_BASE_URL}/portal/two-factor/enable`;
export const GET_TWO_FACTOR_DISABLE_API = () => `${API_BASE_URL}/portal/two-factor/disable`;
export const POST_TWO_FACTOR_VERIFY_API = () => `${API_BASE_URL}/portal/two-factor/verify`;
export const POST_TWO_FACTOR_LOGIN_API = () => `${API_BASE_URL}/portal/two-factor/login`;

// Transactions

// ****************************** Financial ****************************** //
//
export const TRANSACTIONS_API = () => `${API_BASE_URL}/admin/transactions`;
export const GET_WALLETS_API = () => `${API_BASE_URL}/admin/wallets`;
export const GET_SYSTEM_PAYMENT_METHOD_API = () => `${API_BASE_URL}/admin/system-payment-methods`;

// Transactions Client Section
export const POST_WALLET_NUMBER_API = () => `${API_BASE_URL}/portal/wallet`;
export const POST_WALLET_TRANSFER_API = () => `${API_BASE_URL}/admin/wallet/transfer`;

// ****************************** Accounts ****************************** //
//
export const ACCOUNTS_API = () => `${API_BASE_URL}/admin/accounts`;
export const GET_ACCOUNTS_TYPE_API = () => `${API_BASE_URL}/account-types`;
// export const GET_LEVERAGE_API = () => `${API_BASE_URL}/leverages`;
export const GET_LEVERAGE_API = (id) => `${API_BASE_URL}/leverages?accountTypeId=${id}`;
export const PUT_UPDATE_USER_LEVERAGE_API = (login) => `${API_BASE_URL}/admin/accounts/leverage/${login}`;
export const POST_ACCOUNTS_RESET_PASSWORD = () => `${API_BASE_URL}/admin/accounts/password/reset`;
export const POST_ACCOUNT_DEPOSIT_API = () => `${API_BASE_URL}/portal/accounts/deposit`;
export const POST_ACCOUNT_WITHDRAWAL_API = () => `${API_BASE_URL}/admin/accounts/withdrawal`;

// ****************************** Support ****************************** //
// Faqs
export const FAQS = () => `${API_BASE_URL}/faqs`;
export const FAQS_CATEGORIES = () => `${API_BASE_URL}/faq-categories`;

// Platform
export const GET_PLATFORM_API = () => `${API_BASE_URL}/platforms/`;

// Tickets

export const ACCOUNT_TICKETS_API = () => `${API_BASE_URL}/admin/tickets/`;
export const POST_ACCOUNT_TICKET_MESSAGE_API = () => `${API_BASE_URL}/admin/ticket-messages/`;
export const GET_TICKET_DEPARTMENTS_API = () => `${API_BASE_URL}/admin/ticket-departments/`;
export const GET_ACCOUNT_TICKET_API = (id) => `${API_BASE_URL}/admin/tickets/${id}`;
export const DELETE_ACCOUNT_TICKET_API = (id) => `${API_BASE_URL}/admin/tickets?tickets=[${id}]`;








// Auth
export const CONFIRM_USER_MAIL = (id) => `${API_BASE_URL}/portal/users/confirmed-email/${id}`;
export const CONFIRM_USER = (id) => `${API_BASE_URL}/portal/users/verified/${id}`;
export const GET_CASHBACK_API = () => `${API_BASE_URL}/portal/cashback-users?`;
export const TOTAL_CASHBACK_API = () => `${API_BASE_URL}/portal/cashback-users/total-cashback?`;
export const EMAIL_CONFIRMATION_GET_API = () =>
  `${API_BASE_URL}/portal/email-confirmation`;
export const SEND_EMAIL_CONFIRMATION_POST_API = () =>
  `${API_BASE_URL}/portal/send-email-confirmation`;
export const SMS_POST_API = () => `${API_BASE_URL}/portal/login-sms`;
export const SMS_VERIFY_POST_API = () => `${API_BASE_URL}/portal/sms-confirmation`;
export const SMS_VERIFY_GET_API = () => `${API_BASE_URL}/portal/send-sms-confirmation`;
export const SMS_CHANGE_NUMBER_API = () => `${API_BASE_URL}/portal/change-phone`;
export const SMS_RESEND_CODE_API = () => `${API_BASE_URL}/portal/resend-code`;
export const GET_USER_OPEN_POSITIONS = (login) => `${API_BASE_URL}/portal/open-positions/${login}`;
export const GET_USER_DEALS_HISTORY = (login) => `${API_BASE_URL}/portal/deals/${login}?`;
export const TOTAL_LOT_GET_API = () => `${API_BASE_URL}/portal/deals-history/total-lot?`;
export const ALL_COMMISSION_GET_API = () => `${API_BASE_URL}/portal/deals-history/total-commission?`;

// export const SEND_SMS_POST_API = () => `${API_BASE_URL}/sms/send`;

// User
export const USER_ME_GET_API = () => `${API_BASE_URL}/portal/users/me`;
export const USER_GET_API = (id) => `${API_BASE_URL}/portal/users/${id}`;
export const PROFILE_PUT_API = (id) => `${API_BASE_URL}/portal/users/${id}`;
export const USER_SEND_DOCUMENTS = () => `${API_BASE_URL}/documents`;
export const USER_GET_DOCUMENTS_OLD = (id) => `${API_BASE_URL}/documents?user=${id}`;
export const USER_DOCUMENTS_ACTION = (id) => `${API_BASE_URL}/documents/${id}`;

//User Emails
export const USER_MAIL_API = () => `${API_BASE_URL}/emails`;
export const GET_MAIL_BY_ID_API = () => `${API_BASE_URL}/emails`;
export const USER_GET_MAIL_API = (type) => `${API_BASE_URL}/emails/${type}`;
export const GET_MAIL_LABELS_API = () => `${API_BASE_URL}/mail-labels`;
export const MAIL_PUT_MULTIPLE = () => `${API_BASE_URL}/emails/many`;
export const GET_MAIL_LABELS_DATA_API = (labelid) => `${API_BASE_URL}/emails/all?label=${labelid}`;

// export const GET_USERS_API = (limit, start, filter) =>
//   `${API_BASE_URL}/portal/users`;


export const USERS_POST_API = () => `${API_BASE_URL}/users`;
export const IDENTITY_APPROVE_CODE_POST_API = () =>
  `${API_BASE_URL}/portal/identity-approve-code`;
export const SECURITY_PIN_GET_API = () => `${API_BASE_URL}/portal/user/security-pin`;
export const NOTIFICATION_GET_API = () => `${API_BASE_URL}/portal/notification`;

export const UPDATE_USER_NOTES = (id) => `${API_BASE_URL}/admin/notes/${id}`;
export const ADD_USER_NOTES = () => `${API_BASE_URL}/admin/notes`;
export const GET_USER_NOTES = () => `${API_BASE_URL}/admin/notes`;
export const DELETE_USER_NOTES = (id) => `${API_BASE_URL}/admin/notes?notes=${id}`;

export const GET_USER_STATUS = () => `${API_BASE_URL}/status-users`;
export const UPDATE_USER_STATUS = () => `${API_BASE_URL}/admin/clients/update/status`;
export const GET_ALL_SUPPORTERS = () => `${API_BASE_URL}/portal/supporters`;
export const ASSIGN_SUPPORT_API = () => `${API_BASE_URL}/admin/clients/update/supporter`;
export const UPDATE_USER_LEVERAGE_API_2 = (id) => `${API_BASE_URL}/accounts/leverage/${id}`;
export const RESET_ACCOUNT_PASSWORD = () => `${API_BASE_URL}/portal/account-reset-password`;
export const CHANGE_ACCOUNT_PASSWORD = () => `${API_BASE_URL}/portal/account-change-password`;

// Wallet & Transactions

export const WALLETS_GET_API = () => `${API_BASE_URL}/portal/wallets?`;
export const ACTIVE_GET_USERS_API = () => `${API_BASE_URL}/portal/activeuser?`;
export const COMMISSION_CALCULATE_GET_API = () => `${API_BASE_URL}/portal/commissioncalculate?`;
export const TRANSACTIONS_PUT_API = (id) => `${API_BASE_URL}/transactions/${id}`;
export const SUM_OF_TRANSACTION_GET_API = (id) => `${API_BASE_URL}/portal/sum-of-transactions${id ? '?id=' + id : ''}`;
export const TRANSACTIONS_POST_API = () => `${API_BASE_URL}/admin/transactions`;
export const TRANSACTIONS_APPROVE_POST_API = () => `${API_BASE_URL}/portal/transactions/action`;
export const AFFILIATE_COMMISSION_GET_API = () => `${API_BASE_URL}/affiliate-commission-rates?_sort=account_type:asc,minLot:asc`;
export const FINANCIAL_STATISTICS_GET_API = () => `${API_BASE_URL}/payouts-histories`;
export const FUNDING_METHOD_SETTING_GET_API = () => `${API_BASE_URL}/funding-method-settings?enabled=true`;
export const PAYMENT_METHOD_GET_API = () => `${API_BASE_URL}/payment-methods`;
export const PAYMENT_METHOD_POST_API = () => `${API_BASE_URL}/payment-methods`;
export const PAYMENT_METHOD_DELETE_API = (id) => `${API_BASE_URL}/payment-methods/${id}`;
export const PAYMENT_METHOD_UPDATE_API = (id) => `${API_BASE_URL}/payment-methods/${id}`;
export const SYNC_USER_ACCOUNTS_GET_API = () => `${API_BASE_URL}/portal/sync-users`;
export const GET_ACCOUNT_DATA = (user) => `${API_BASE_URL}/accounts?user=${user}`;
export const GET_CALENDAR_API = () => `${API_BASE_URL}/calendars?`;
export const DELETE_CALENDAR_API = (id) => `${API_BASE_URL}/calendars/${id}`;
export const POST_CALENDAR_API = () => `${API_BASE_URL}/calendars`;

// upload
export const UPLOAD_POST_API = () => `${API_BASE_URL}/upload`;


// Comments
export const COMMENTS_GET_API = () => `${API_BASE_URL}/comments?`;
export const CUSTOME_COMMENTS_POST = () => `${API_BASE_URL}/portal/comments`;

// IB Dashboard
export const IB_GET_API = () => `${API_BASE_URL}/portal/ib?`;
export const TOTAL_COMMISSION_GET_API = () => `${API_BASE_URL}/portal/ib-users/total-commission?`;
export const IB_GET_USERS_API = () => `${API_BASE_URL}/portal/ib-users?`;
export const PAYOUTS_HISTORY_GET_API = () => `${API_BASE_URL}/payouts-histories?`;

export const NEW_GET_IB_API = () => `${API_BASE_URL}/admin/ib-users-list`;
export const NEW_GET_IB_USERS_API = () => `${API_BASE_URL}/admin/ib-users`;


// Tickets
export const TICKETS_GET_API = (limit, start, filter) =>
  `${API_BASE_URL}/tickets?_limit=${limit}&_start=${start}&type_in=support&type_in=accounting&type_in=affiliate&${serializeArray(
    filter
  )}`;
export const COUNT_TICKETS_GET_API = (filter) =>
  `${API_BASE_URL}/tickets/count?type_in=support&type_in=accounting&type_in=affiliate&${serializeArray(
    filter
  )}`;
export const CUSTOM_TICKETS_POST_API = () => `${API_BASE_URL}/portal/tickets`;
export const TICKETS_POST_API = () => `${API_BASE_URL}/tickets`;
export const TICKET_GET_API = (id) => `${API_BASE_URL}/tickets/${id}`;

// Inboxs
export const INBOXS_GET_API = (limit, start, filter) =>
  `${API_BASE_URL}/system-messages?_limit=${limit}&_start=${start}&${serializeArray(
    filter
  )}`;
export const COUNT_INBOXS_GET_API = (filter) =>
  `${API_BASE_URL}/system-messages/count?${serializeArray(filter)}`;
export const INBOX_GET_API = (id) => `${API_BASE_URL}/system-messages/${id}?`;

// Accounts
export const ACCOUNTS_GET_API = (limit, start, filter) =>
  `${API_BASE_URL}/accounts?_limit=${limit}&_start=${start}&${serializeArray(
    filter
  )}`;
export const COUNT_ACCOUNTS_GET_API = (filter) =>
  `${API_BASE_URL}/accounts/count?${serializeArray(filter)}`;
export const ACCOUNTS_POST_API = () => `${API_BASE_URL}/admin/accounts`;
export const ACCOUNT_GET_API = (id) => `${API_BASE_URL}/accounts/${id}`;
export const ACCOUNT_HISTORY_GET_API = ({ id, from, to }) =>
  `${API_BASE_URL}/accounts-histories?account=${id}&_limit=-1&created_at_gt=${from}&created_at_lt=${to}`;
  export const ACCOUNT_HISTORY_TOTAL_GET_API = ({ id }) =>
    `${API_BASE_URL}/accounts-histories?account=${id}&_limit=-1`;

export const CREATE_ACCOUNT_POST_API = () => `${API_BASE_URL}/portal/accounts`;
export const CHANGE_ACCOUNT_PASSWORD_POST_API = () => `${API_BASE_URL}/portal/accounts/change-password`;
export const CHANGE_USER_PASSWORD_POST_API = () => `${API_BASE_URL}/portal/user/change-password`;

// Partership
export const CASH_BACK_AFFILIATE_GET_API = () => `${API_BASE_URL}/portal/cashback?`;

// Webhook
export const COUNTRY_IP_GET_API = () => `https://extreme-ip-lookup.com/json/`;

export const GET_PHONE_TRADING_GET_API = () => `${API_BASE_URL}/phone-services`;
export const SET_PHONE_TRADING_POST_API = () => `${API_BASE_URL}/phone-services`;
export const UPDATE_PHONE_TRADING_GET_API = () => `${API_BASE_URL}/phone-services`;
export const TWO_FACTOR_ENABLE_GET_API = () => `${API_BASE_URL}/portal/two-factor/enable`;
export const TWO_FACTOR_DISABLE_GET_API = () => `${API_BASE_URL}/portal/two-factor/disable`;
export const TWO_FACTOR_VERIFY_POST_API = () => `${API_BASE_URL}/portal/two-factor/verify`;
export const TWO_FACTOR_POST_ADMIN_LOGIN_API = () => `${API_BASE_URL}/portal/two-factor/login`;

// exchnage
export const EXCHNAGE_GET_API = () => `${API_BASE_URL}/exchange`;

// promotions
export const CLAIME_PROMOTION_GET_API = (id) =>
  `${API_BASE_URL}/portal/promotions/${id}`;

export const PROMOTIONS_GET_API = (limit, start, filter) =>
  `${API_BASE_URL}/account-bonus-histories?_limit=${limit}&_start=${start}&${serializeArray(
    filter
  )}`;
export const COUNT_PROMOTIONS_GET_API = (filter) =>
  `${API_BASE_URL}/account-bonus-histories/count?${serializeArray(filter)}`;

// VPS
export const GET_USER_VPS = () => `${API_BASE_URL}/admin/vps`;
export const GET_HETZNER_VPS = () => `${API_BASE_URL}/admin/vps-hetzner`;
export const PUT_HETZNER_VPS_OFF = (hetznerId) => `${API_BASE_URL}/admin/vps/poweroff/${hetznerId}`;
export const PUT_HETZNER_VPS_ON = (hetznerId) => `${API_BASE_URL}/admin/vps/poweron/${hetznerId}`;
export const PUT_HETZNER_VPS_RESET = (hetznerId) => `${API_BASE_URL}/admin/vps/reset/${hetznerId}`;
export const PUT_HETZNER_VPS_CHANGE_NAME = (hetznerId) => `${API_BASE_URL}/admin/vps/change-name/${hetznerId}`;
export const DELETE_HETZNER_VPS = (hetznerId) => `${API_BASE_URL}/admin/vps/${hetznerId}`;
export const POST_CREATE_HETZNER_VPS = () => `${API_BASE_URL}/admin/vps`;