import axios from "axios";
import {
  GET_USERS_API,
  USER_API,
  UPDATE_EMAIL_CONFIRMATION_API,
  UPDATE_PHONE_CONFIRMATION_API,
  SEND_EMAIL_CONFIRMATOIN_API,
  SEND_OTP_EMAIL_API,
  UPDATE_USER_STATUS,
  GET_ALL_SUPPORTERS,
  ASSIGN_SUPPORT_API,
  NEW_GET_IB_API,
  NEW_GET_IB_USERS_API,


  PROFILE_PUT_API,
  UPLOAD_POST_API,
  TRANSACTIONS_POST_API,
  NOTIFICATION_GET_API,
  PAYMENT_METHOD_POST_API,
  PAYMENT_METHOD_GET_API,
  PAYMENT_METHOD_DELETE_API,
  USER_GET_API,
  AFFILIATE_COMMISSION_GET_API,
  FINANCIAL_STATISTICS_GET_API,
  USER_AVATAR_API,
  FUNDING_METHOD_SETTING_GET_API,
  PAYMENT_METHOD_UPDATE_API,
  UPDATE_USER_NOTES,
  ADD_USER_NOTES,
  GET_USER_NOTES,
  GET_USER_OPEN_POSITIONS,
  GET_USER_DEALS_HISTORY,
  USER_MAIL_API,
  USER_GET_MAIL_API,
  GET_MAIL_BY_ID_API,
  GET_MAIL_LABELS_API,
  GET_MAIL_LABELS_DATA_API,
  MAIL_PUT_MULTIPLE,
  GET_ACCOUNT_DATA,
  CASH_BACK_AFFILIATE_GET_API,
  IB_GET_API,
  PAYOUTS_HISTORY_GET_API,
  ACCOUNTS_POST_API,
  GET_CALENDAR_API,
  DELETE_CALENDAR_API,
  POST_CALENDAR_API,
  ADVERTISING_MEDIA_GET_API,
  CHART_GET_API,
  WALLETS_GET_API,
  IB_GET_USERS_API,
  GET_USER_STATUS,
  GET_LEVERAGE_API,
  RESET_ACCOUNT_PASSWORD,
  CHANGE_ACCOUNT_PASSWORD,
  ACCOUNT_GET_API,
  CONFIRM_USER,
  GET_COUNT_USERS_API,
  GET_CASHBACK_API,
  SETTING_GET_API,
  TOTAL_CASHBACK_API,
  TOTAL_COMMISSION_GET_API, USER_REMOVE_API, DELETE_USER_NOTES,
} from "constants/ApiConstant";

export const getUsers = (params) => {
  let url = GET_USERS_API();
  if (params) { url += `?${Object.keys(params).map(item => `${encodeURIComponent(item)}=${(params[item])}`).join('&')}` }
  return axios.get(url);
};

export const getUserData = (clientNumber) => {
  const url = USER_API(clientNumber);
  return axios.get(url);
}

export const removeUserProfile = (users) => {
  const url = USER_REMOVE_API(users);
  return axios.delete(url);
};

export const updateUserStatus = (data) => {
  const url = UPDATE_USER_STATUS();
  return axios.put(url, data);
};

export const getAllSupporters = () => {
  const url = GET_ALL_SUPPORTERS();
  return axios.get(url);
};

export const assignSupporter = (data) => {
  const url = ASSIGN_SUPPORT_API();
  return axios.put(url, data);
};

export const updateEmailConfirmation = (clientNumber, data) => {
  const url = UPDATE_EMAIL_CONFIRMATION_API(clientNumber);
  return axios.put(url, data);
};

export const updatePhoneConfirmation = (clientNumber, data) => {
  const url = UPDATE_PHONE_CONFIRMATION_API(clientNumber);
  return axios.put(url, data);
};

export const sendEmailConfirmation = (data) => {
  const url = SEND_EMAIL_CONFIRMATOIN_API();
  return axios.post(url, data);
};

export const sendOTPEmail = (data) => {
  const url = SEND_OTP_EMAIL_API();
  return axios.put(url, data);
};

export const getIBList = (params) => {
  let url = NEW_GET_IB_API();
  if (params) { url += `?${Object.keys(params).map(item => `${encodeURIComponent(item)}=${(params[item])}`).join('&')}` }
  return axios.get(url);
};

export const getIBUsers = (params) => {
  let url = NEW_GET_IB_USERS_API();
  if (params) { url += `?${Object.keys(params).map(item => `${encodeURIComponent(item)}=${(params[item])}`).join('&')}` }
  return axios.get(url);
};






export const accountPost = (data) => {
  const url = ACCOUNTS_POST_API();
  console.log("ulr",url)
  console.log("data",data)
  return axios.post(url, data);
};

export const getCashBackAffiliate = (userid, filters) => {
  let url = CASH_BACK_AFFILIATE_GET_API();
  url += `id=${userid}&from=${filters?.from}&to=${filters?.to}`;
  return axios.get(url);
};


export const getTotalCommission = (filters) => {
  let url = "";
  url = TOTAL_COMMISSION_GET_API();
  url += `from=${filters?.from}&to=${filters?.to}`;
  return axios.get(url);
};

export const getPayoutsHistory = (filters) => {
  let url = PAYOUTS_HISTORY_GET_API();
  url += `from=${filters?.from}&to=${filters?.to}`;
  return axios.get(url);
};

export const profilePut = (id, data) => {
  const url = PROFILE_PUT_API(id);
  return axios.put(url, data);
};

export const uploadFile = (data) => {
  const url = UPLOAD_POST_API();
  const formData = new FormData();
  formData.append("files", data);
  const contentType = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return axios.post(url, formData, contentType);
};

export const profileMe = (id = "me") => {
  const url = USER_GET_API(id);
  return axios.get(url);
};

export const notificationGet = () => {
  const url = NOTIFICATION_GET_API();
  return axios.get(url);
};

export const transactionsPost = (data) => {
  const url = TRANSACTIONS_POST_API();
  return axios.post(url, data);
};

export const affiliateCommissionRate = () => {
  const url = AFFILIATE_COMMISSION_GET_API();
  return axios.get(url);
};

export const financialStatistics = () => {
  const url = FINANCIAL_STATISTICS_GET_API();
  return axios.get(url);
};

export const fundingMethodSettingGet = () => {
  let url = FUNDING_METHOD_SETTING_GET_API();
  return axios.get(url);
};

export const paymentMethodsGet = (userId = null) => {
  let url = PAYMENT_METHOD_GET_API();
  if (userId) {
    url += `?user=${userId}`;
  }
  return axios.get(url);
};

export const newPaymentMethodPost = (data) => {
  const url = PAYMENT_METHOD_POST_API();
  return axios.post(url, data);
};

export const paymentMethodDelete = (id) => {
  const url = PAYMENT_METHOD_DELETE_API(id);
  return axios.delete(url);
};

export const paymentMethodUpdate = (id, data) => {
  const url = PAYMENT_METHOD_UPDATE_API(id);
  return axios.put(url, data);
};



export const getCalendar = (filters) => {
  let url = GET_CALENDAR_API();
  url += `from=${filters.from}&to=${filters.to}`;
  return axios.get(url);
};

export const postCalendar = (data) => {
  let url = POST_CALENDAR_API();
  return axios.post(url, data);
};

export const deleteCalendar = (id) => {
  let url = DELETE_CALENDAR_API(id);
  return axios.delete(url);
};



export const uploadUserAvatar = (data) => {
  const url = USER_AVATAR_API();
  var formData = new FormData();
  formData.append("files.avatar", data);
  formData.append("data", "{}");
  const contentType = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return axios.put(url, formData, contentType);
};

export const getAdvertising = () => {
  const url = ADVERTISING_MEDIA_GET_API();
  return axios.get(url);
};

export const chartsGet = (login, filters) => {
  let url = CHART_GET_API();
  url += `login=${login}&from=${filters.from}&to=${filters.to}`;
  return axios.get(url);
};


export const userSendEmail = (data, status) => {
  let finalData = data;
  finalData.status = status;
  var formData = new FormData();
  if (data?.files?.fileList) {
    data.files.fileList.forEach((item) => {
      formData.append("files.file", item.originFileObj);
    });
  }
  delete finalData.files;
  formData.append("data", JSON.stringify(finalData));
  const url = USER_MAIL_API();
  const contentType = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return axios.post(url, formData, contentType);
};

export const getSentMails = (type, id) => {
  let url = USER_GET_MAIL_API(type);
  url += `?createdBy=${id}`;
  return axios.get(url);
};

export const userGetMailInbox = (id) => {
  let url = USER_GET_MAIL_API(id);
  return axios.get(url);
};

export const getMailById = (id) => {
  let url = GET_MAIL_BY_ID_API();
  url += `?id=${id}`;
  return axios.get(url);
};

export const getMailLabels = () => {
  let url = GET_MAIL_LABELS_API();
  return axios.get(url);
};

export const getMailByLabel = (labelid) => {
  let url = GET_MAIL_LABELS_DATA_API(labelid);
  return axios.get(url);
};

export const mailUpdateMultiple = (ids) => {
  let url = MAIL_PUT_MULTIPLE();
  return axios.put(url, ids);
};

export const deleteMail = (ids) => {
  let url = MAIL_PUT_MULTIPLE();
  return axios.put(url, { deleted: true, ...ids });
};

export const getAccountData = (user, query = null) => {
  let url = GET_ACCOUNT_DATA(user);
  url += `query=${query}`;
  return axios.get(url);
};

export const getUserOpenPositions = (login) => {
  const url = GET_USER_OPEN_POSITIONS(login);
  return axios.get(url);
};

export const getUserDealsHistory = (login, filters) => {
  let url = GET_USER_DEALS_HISTORY(login);
  // url += serializeArray(filters);
  url += `from=${filters.from}&to=${filters.to}`;
  return axios.get(url);
};

export const deleteUserAvatar = () => {
  const url = USER_AVATAR_API();
  return axios.delete(url);
};

export const getSetting = () => {
  const url = SETTING_GET_API();
  return axios.get(url);
};

export const addUserNotes = (data) => {
  const url = ADD_USER_NOTES();
  return axios.post(url, data);
};

export const updateUserNotes = (id, data) => {
  const url = UPDATE_USER_NOTES(id);
  return axios.put(url, data);
};

export const getUserNotes = (data) => {
  let url = GET_USER_NOTES(data);
  if (data) { url += `?${Object.keys(data).map(item => `${encodeURIComponent(item)}=${(data[item])}`).join('&')}` }
  return axios.get(url);
};

export const deleteUserNotes = (id) => {
  let url = DELETE_USER_NOTES(id);
  return axios.delete(url);
}

export const getUserStatusList = () => {
  const url = GET_USER_STATUS();
  return axios.get(url);
};

export const walletsGet = (limit, offset, query, amountGt, amountLt) => {
  let url = WALLETS_GET_API();
  url += `limit=${limit}&offset=${offset}&query=${query}&wallet_gt=${amountGt}&wallet_lt=${amountLt}`;
  return axios.get(url);
};

export const getLeverage = (id) => {
  const url = GET_LEVERAGE_API(id);
  return axios.get(url);
};

export const resetAccountPassword = (data) => {
  const url = RESET_ACCOUNT_PASSWORD();
  return axios.put(url, data);
};

export const changeAccountPassword = (data) => {
  const url = CHANGE_ACCOUNT_PASSWORD();
  return axios.put(url, data);
};

export const getAccountSingle = (id) => {
  const url = ACCOUNT_GET_API(id);
  return axios.get(url);
};

export const confirmUserProfile = (id, data) => {
  console.log("data",data)
  const url = CONFIRM_USER(id);
  return axios.put(url, data);
};

export const getActiveMembers = () => {
  const url = GET_COUNT_USERS_API();
  return axios.get(url);
};

export const getCashBack = (filters, start, limit, query) => {
  let url = GET_CASHBACK_API();
  url += `from=${filters?.from}&to=${filters?.to}&start=${start}&limit=${limit}`;
  if (query !== null) {
    url += `&query=${query}`;
  }
  return axios.get(url);
};

export const getTotalCashback = (filters) => {
  let url = TOTAL_CASHBACK_API();
  url += `from=${filters?.from}&to=${filters?.to}`;
  return axios.get(url);
};