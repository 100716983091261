import {
  TOGGLE_COLLAPSED_NAV,
  CHANGE_LOCALE,
  SIDE_NAV_STYLE_CHANGE,
  NAV_TYPE_CHANGE,
  TOP_NAV_COLOR_CHANGE,
  HEADER_NAV_COLOR_CHANGE,
  TOGGLE_MOBILE_NAV,
  SWITCH_THEME,
  DIRECTION_CHANGE
} from '../constants/Theme';
// import { THEME_CONFIG } from 'configs/AppConfig'
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { getSetting } from 'apis/user';
import { SETTING_GET_API } from 'constants/ApiConstant';
import { AUTH_TOKEN } from 'redux/constants/Auth';

const initTheme = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR
};

const theme = (state = initTheme, action) => {
  let setting = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: 'en',
    navType: NAV_TYPE_SIDE,
    topNavColor: '#3e82f7',
    headerNavColor: '',
    mobileNav: false,
    currentTheme: 'light',
    direction: DIR_LTR
  };
  getSetting().then((res) => { setting = {
    navCollapsed: false,
    sideNavTheme: res.data.sideNavTheme,
    locale: 'en',
    navType: res.data.navType,
    topNavColor: res.data.topNavColor,
    headerNavColor: res.data.headerNavColor,
    mobileNav: false,
    currentTheme: 'light',
    direction: DIR_LTR
  }});
  switch (action.type) {
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...setting,
        navCollapsed: action.navCollapsed
      };
    case SIDE_NAV_STYLE_CHANGE:
      return {
        ...setting,
        sideNavTheme: action.sideNavTheme
      };
    case CHANGE_LOCALE:
      return {
        ...setting,
        locale: action.locale
      };
    case NAV_TYPE_CHANGE:
      return {
        ...setting,
        navType: action.navType
      };
    case TOP_NAV_COLOR_CHANGE:
      return {
        ...setting,
        topNavColor: action.topNavColor
      };
    case HEADER_NAV_COLOR_CHANGE:
      return {
        ...setting,
        headerNavColor: action.headerNavColor
      };
    case TOGGLE_MOBILE_NAV:
      return {
        ...setting,
        mobileNav: action.mobileNav
      };
    case SWITCH_THEME:
      return {
        ...setting,
        currentTheme: action.currentTheme
      }
    case DIRECTION_CHANGE:
      return {
        ...setting,
        direction: action.direction
      }
    default:
      return setting;
  }
};

export default theme