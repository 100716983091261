import React, { Component } from "react";
import Views from './views';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";

class Routers extends Component {
    async  componentDidMount() {
        const token = this.props.auth.token;

        if(token){
            axios.defaults.headers.common.Authorization = 'Bearer ' + token;
        }
    }
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/" component={Views}/>
                </Switch>
            </Router>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(Routers);
