import React, { Component } from "react";
import { Provider } from 'react-redux';
import {store, persistor} from './redux/store';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from './configs/AppConfig';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { PUBLIC_RECAPTCHA_KEY } from 'configs/AppConfig';
import 'assets/css/antdCustom.css';
import { PersistGate } from 'redux-persist/integration/react';
import Routers from "./Routers";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

class App extends Component {
    render() {
      return (
        <div className="App">
          <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                  <GoogleReCaptchaProvider
                      useRecaptchaNet
                      reCaptchaKey={PUBLIC_RECAPTCHA_KEY}
                      scriptProps={{ async: true, defer: true, appendTo: 'body' }}
                  >
                      <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
                          <Routers/>
                      </ThemeSwitcherProvider>
                  </GoogleReCaptchaProvider>
              </PersistGate>
          </Provider>
        </div>
      );
    }
}

export default App;
